<template>
  <div class="nav-list" :class="{ scale: navScale }">
    <div class="title">
      <div class="user-info">
        <span class="user-avatar"
          ><img
            :src="
              (userInfo.avatarUrl &&
                $store.state.assetsUrl + userInfo.avatarUrl) ||
              DefaultAvatarUrl
            "
            alt=""
        /></span>
        <span>{{ userInfo.nickName }}</span>
        <span class="post-btn" @click="goPost"
          ><el-button icon="el-icon-picture" size="mini" type="primary"
            >发布</el-button
          ></span
        >
      </div>
      <ul class="type-list">
        <li :class="{ active: sideBarType == 'list' }" @click="goPage('list')">
          我的发布
        </li>
        <li
          :class="{ active: sideBarType == 'collect' }"
          @click="goPage('collect')"
        >
          我的收藏
        </li>
        <li
          :class="{ active: sideBarType == 'download' }"
          @click="goPage('download')"
        >
          我的下载
        </li>
      </ul>
    </div>
    <div ref="sideBar">
      <ul class="nav-list-wrapper">
        <li
          class="nav-list-item"
          :class="{ active: activeCategory == item.value }"
          @click="toggleCategory(item)"
          v-for="(item, index) in categoryList"
          :key="index"
        >
          <span class="item-label">{{ item.label }}</span>
        </li>
      </ul>
      <ul class="menu-list" v-if="activeCategory != ''">
        <li
          class="menu-item sub"
          :class="{ active: !activeType }"
          @click="toggleType(null)"
        >
          <a class="menu-item-link" href="javascript:;">
            <img
              class="menu-item-img"
              src="../../assets/imgs/icon_folder.png"
            />
            <span class="menu-item-name">全部</span>
          </a>
        </li>
        <li
          class="menu-item sub"
          :class="{ active: activeType == item.id }"
          v-for="(item, index) in typesList"
          :key="index"
          @click="toggleType(item)"
        >
          <a class="menu-item-link" href="javascript:;">
            <img
              class="menu-item-img"
              :src="item.icon && $store.state.assetsUrl + item.icon"
              :alt="item.typeName"
            />
            <span class="menu-item-name">{{ item.typeName }}</span>
          </a>
        </li>
      </ul>
      <div class="filter" v-if="sideBarType == 'list'">
        <el-form
          :model="formData"
          label-width="80px"
          label-position="left"
          inline
          size="small"
        >
          <el-form-item label="审核状态">
            <el-radio-group v-model="formData.status" @input="changeStatus">
              <el-radio label="">全部</el-radio>
              <el-radio
                v-for="(item, index) in auditStatusList"
                :key="index"
                :label="item.value"
                >{{ item.label }}</el-radio
              >
            </el-radio-group>
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
import DefaultAvatarUrl from "../../assets/imgs/default_avatar.png";
import { setOptions } from "../../utils";
import { mapState } from "vuex";

export default {
  props: {
    navScale: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      DefaultAvatarUrl,
      formData: {
        status: this.$route.query.status || "",
      },
      typesList: [],
      activeCategory: this.$route.params.category || "",
      activeType: this.$route.query.type || "",
    };
  },
  components: {
    UserSideBar: () => import("../../components/common/SideBar.vue"),
  },
  created() {
    this.getTypesList();
  },
  computed: {
    ...mapState(["baseUrl", "userInfo"]),
    status() {
      return this.$route.query.status || "";
    },
    type() {
      return this.$route.query.type || "";
    },
    category() {
      return this.$route.params.category || "";
    },
    sideBarType() {
      return this.$route.meta.sideBar;
    },
    auditStatusList() {
      return setOptions("audit_status_list").map((v) => {
        v.value = v.value - 0;
        return v;
      });
    },
    categoryList() {
      return [{ label: "全部", value: "", icon: "" }].concat(
        setOptions("category_list")
      );
    },
  },
  methods: {
    // 切换类型
    toggleType(item) {
      if (item && this.activeType == item.id) return;

      let routerPath =
        "/resources/" + this.sideBarType + "/" + this.activeCategory;
      if (!item) {
        this.activeType = null;
      } else {
        this.activeType = item.id;
      }
      this.$router.push({
        path: routerPath,
        query: {
          type: this.activeType,
          status: this.formData.status,
        },
      });
    },
    changeStatus(e) {
      console.log(e);
      this.$router.push({
        path: this.$route.fullPath,
        query: {
          status: this.formData.status,
        },
      });
    },
    toggleCategory(item) {
      this.activeCategory = item.value;
      this.activeType = "";
      this.formData.status = "";
      this.getTypesList();
      this.$router.push({
        path: "/resources/" + this.sideBarType + "/" + item.value,
      });
    },
    goPage(page) {
      this.activeCategory = "";
      this.activeType = "";
      this.formData.status = "";
      this.getTypesList();
      this.$router.push("/resources/" + page);
    },
    goPost() {
      this.$router.replace("/resources/post");
    },
    // 根据分类加载对应废品类型
    getTypesList() {
      this.$get("/types/typesByCategory", {
        category: this.activeCategory,
      }).then((res) => {
        this.typesList = res.data || [];
      });
    },
  },
  watch: {
    category(newV) {
      this.activeCategory = newV;
      this.getTypesList();
    },
    type(newV) {
      this.activeType = newV;
    },
    status(newV) {
      this.formData.status = newV && newV - 0 || "";
    },
  },
};
</script>

<style lang="scss" scoped>
.nav-list {
  padding: 20px;
  max-width: 1575px;
  margin: 0 auto;
  transition: 0.3s;

  &.scale {
    padding-bottom: 20px;
    position: fixed;
    top: 60px;
    left: 0;
    width: 100%;
    z-index: 200;

    &::after {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: #1d1f22;
      opacity: 0.98;
      z-index: -1;
    }

    .title {
      display: none;
    }
  }

  .nav-list-wrapper {
    display: block;

    .nav-list-item {
      cursor: pointer;
      margin-bottom: 20px;
      display: inline-block;
      margin-right: 40px;
      font-size: 14px;

      .item-label {
        color: #c8c7cc;
        display: inline-block;
      }

      .item-icon {
        width: 100%;
        display: block;
      }

      &:nth-last-of-type(1) {
        margin-right: 0;
      }

      &.active,
      &:hover {
        box-shadow: none;

        .item-label {
          color: #5fc7bc;
          text-decoration: underline;
          text-underline-offset: 5px;
        }
      }

      &.active {
        font-size: 16px;
        box-shadow: none;
      }
    }
  }

  .menu-list {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin-bottom: 20px;
    min-height: 40px;

    .menu-item {
      .menu-item-link {
        margin-right: 20px;
        line-height: 32px;
        height: 32px;
        padding: 0 10px;
        display: inline-block;

        span,
        img {
          vertical-align: middle;
        }

        .menu-item-img {
          width: 18px;
          height: 18px;
          margin-right: 5px;
        }

        .menu-item-name {
          font-size: 14px;
          color: #c8c7cc;
        }
      }

      &.active {
        .menu-item-link {
          background: #0f5bff;
          border-radius: 4px;

          .menu-item-name {
            color: #fff;
          }
        }
      }
    }
  }

  .el-form-item {
    margin-bottom: 0;
  }
}

.title {
  border-bottom: 1px solid #fff;
  margin-bottom: 20px;
  padding-bottom: 5px;

  display: flex;
  justify-content: space-between;
  align-items: center;

  .user-info {
    font-size: 14px;
    color: #fff;

    span {
      vertical-align: middle;
      display: inline-block;
      margin-right: 10px;
    }

    .user-avatar {
      width: 24px;
      height: 24px;

      img {
        width: 100%;
        height: 100%;
        display: block;
      }
    }

    .post-btn {
      margin-left: 10px;

      .el-button {
        height: 24px;
        line-height: 24px;
        padding-top: 0;
        padding-bottom: 0;
      }
    }
  }

  .type-list {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    position: relative;
    top: 5px;

    li {
      color: #c8c7cc;
      margin-left: 30px;
      font-size: 14px;
      cursor: pointer;
      padding-bottom: 5px;

      &.active {
        font-size: 16px;
        color: #fff;
        border-bottom: 1px solid #fff;
      }

      &:hover {
        color: #fff;
      }
    }
  }
}
</style>